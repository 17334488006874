<template>
    <div :key="refreshKey" class="overflow-hidden">
        <NuxtLink :to="localePath(slug)" class="flex items-center -translate-x-1/2" style="width: 200vw">
            <div
                v-for="i in 6"
                :key="`marquee-${slug}-${i}`"
                class="flex items-center w-full marquee-inner"
                :class="{ 'hidden lg:flex': i % 3 === 0, 'hidden sm:flex': i % 2 === 0 }"
            >
                <Button
                    :label="label"
                    icon="pi pi-chevron-right"
                    class="p-button-secondary p-button-text pointer-events-none"
                />
                <div class="h-[1px] border-b border-dashed border-black mx-4 sm:mx-6" style="flex: 1"></div>
            </div>
        </NuxtLink>
    </div>
</template>

<script>
export default {
    name: 'SectionCloserLinkBar',
    props: {
        slug: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            refreshKey: 0,
        };
    },
    watch: {
        // fix rendering error on window resize
        '$store.state.windowWidth'() {
            this.refreshKey++;
        },
    },
};
</script>

<style>
@keyframes marquee {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
}

.marquee-inner {
    animation-name: marquee;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: 10s;
    @media (min-width: 992px) {
        animation-duration: 15s;
    }
}
</style>
