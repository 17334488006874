<template>
    <article class="anchor-card h-full">
        <a :href="url" target="_blank" class="block">
            <div class="relative base-responsive-rounding mb-4">
                <div class="relative thumbnail aspect-h-2 aspect-w-3">
                    <img v-lazy-load :data-src="img" :alt="title" />
                </div>
                <EventSeriesBadge v-if="hasSeries" />
            </div>

            <div class="font-Poppins font-bold" :class="{ 'mb-2': basic }">
                {{ date }}
            </div>
            <div v-if="!basic && location" class="uppercase">{{ location }}</div>
            <div v-if="!basic" class="w-16 bg-black my-2" style="height: 1px"></div>
            <h3 class="font-Poppins text-base sm:text-lg font-semibold sm:line-clamp-2">
                {{ title }}
            </h3>
        </a>
    </article>
</template>

<script>
import EventSeriesBadge from '~/components/UI/EventSeriesBadge.vue';

export default {
    name: 'ProgramRecommendationCard',
    components: {
        EventSeriesBadge,
    },
    props: {
        basic: {
            type: Boolean,
            required: false,
            default: false,
        },
        url: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        img: {
            type: String,
            required: true,
        },
        date: {
            type: String,
            required: true,
        },
        location: {
            type: String,
            required: false,
            default: null,
        },
        hasSeries: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style scoped></style>
