<template>
    <div id="searchbar" ref="searchbar">
        {{/* Background overlay */}}
        <div
            class="z-1 fixed hidden lg:block top-0 left-0 w-full h-full bg-black transition-opacity"
            :class="openPanelID ? 'opacity-60 pointer-events-auto' : 'opacity-0 pointer-events-none'"
            @click="closeAllPanels"
        ></div>

        <div class="z-2 relative w-full">
            <div
                class="flex flex-col lg:flex-row items-center bg-white px-6 pt-2 lg:pt-0 lg:pl-4 lg:pr-0 rounded-3xl lg:rounded-full shadow-xl"
                style="flex: 1"
            >
                {{/* Date */}}
                <div class="filter-block static" :class="{ active: openPanelID === 1 }">
                    <button class="filter-block__header" @click="() => togglePanel(1)">
                        <div class="text-left">
                            <div class="filter-block__title"><T t="programs_in_varosliget.date_filter_label" /></div>
                            <div v-if="!selectedRange"><T t="programs_in_varosliget.date_filter_label_alt" /></div>
                            <div
                                v-else-if="
                                    selectedDatepickerDateRange.length &&
                                    !rangeOptions.find((range) => range.value === selectedRange)
                                "
                            >
                                {{ customSelectedDateOptionLabelText }}
                            </div>
                            <div v-else>{{ rangeOptions.find((range) => range.value === selectedRange).label }}</div>
                        </div>
                        <div class="filter-block__chevron">
                            <i class="pi pi-chevron-down"></i>
                        </div>
                    </button>
                    <div v-if="isCalendarMounted" class="filter-block__panel lg:-bottom-4">
                        <div class="filter-block__panel-content">
                            <div class="lg:flex lg:justify-center lg:bg-gray-200 lg:-mt-4 lg:-mx-4 lg:py-8 lg:px-4">
                                <div
                                    v-for="option in rangeOptions"
                                    :key="`dateOption-${option.id}`"
                                    class="p-field flex items-start py-2 lg:px-3 lg:py-0"
                                >
                                    <RadioButton
                                        :id="`chk-date-${option.value}`"
                                        v-model="selectedRange"
                                        :input-id="`chk-date-${option.value}`"
                                        name="date"
                                        :value="option.value"
                                    />
                                    <label :for="`chk-date-${option.value}`">{{ option.label }}</label>
                                </div>

                                <div
                                    v-if="
                                        selectedDatepickerDateRange.length &&
                                        !rangeOptions.some(
                                            (range) => range.value === selectedDatepickerDateRange.join('_')
                                        )
                                    "
                                    class="p-field flex items-start py-2 lg:px-3 lg:py-0"
                                >
                                    <RadioButton
                                        :id="`chk-date-custom`"
                                        v-model="selectedRange"
                                        :input-id="`chk-date-custom`"
                                        name="date"
                                        :value="selectedDatepickerDateRange.join('_')"
                                    />

                                    <label :for="`chk-date-custom`">
                                        {{ customSelectedDateOptionLabelText }}
                                    </label>
                                </div>
                            </div>

                            <Button
                                :label="$t('programs_in_varosliget.date_filter_custom_date_button_label')"
                                class="p-button-info p-button-outlined w-full mt-4 lg:hidden"
                                @click.native="openCalendarModal"
                            />

                            {{/* Datepicker ( desktop screens ) */}}
                            <div class="hidden lg:block xl:px-8 3xl:px-32">
                                <ModifiedPrimevueCalendar
                                    v-model="tempDatepickerDateRange"
                                    selection-mode="range"
                                    :number-of-months="2"
                                    date-format="YYYY-MM-DD"
                                    :min-date="new Date()"
                                    :responsive-options="[{ breakpoint: '640px', numMonths: 1 }]"
                                    inline
                                    style="min-width: 100%"
                                    @date-select="onCalendarDateSelect"
                                />

                                <div v-if="false" class="text-center mt-8">
                                    <Button :label="$t('generic.search')" @click.native="search" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {{/* Category */}}
                <div class="filter-block relative" :class="{ active: openPanelID === 2 }">
                    <button class="filter-block__header" @click="() => togglePanel(2)">
                        <div class="text-left">
                            <div class="filter-block__title"><T t="programs_in_varosliget.type_filter_label" /></div>
                            <div v-if="!selectedCategory"><T t="programs_in_varosliget.type_filter_label_alt" /></div>
                            <div v-else>{{ selectedCategory.label }}</div>
                        </div>
                        <div class="filter-block__chevron">
                            <i class="pi pi-chevron-down"></i>
                        </div>
                    </button>
                    <div class="filter-block__panel lg:-bottom-8">
                        <div class="filter-block__panel-content">
                            <div
                                v-for="option in categoryOptions"
                                :key="`dateOption-${option.id}`"
                                class="p-field flex items-start py-2"
                            >
                                <RadioButton
                                    :id="`radio-category-${option.value}`"
                                    v-model="selectedCategory"
                                    :input-id="`radio-category-${option.value}`"
                                    name="category"
                                    :value="option"
                                />
                                <label :for="`radio-category-${option.value}`">{{ option.label }}</label>
                            </div>

                            <div v-if="false" class="text-center mt-8 hidden lg:block">
                                <Button :label="$t('generic.search')" @click.native="search" />
                            </div>
                        </div>
                    </div>
                </div>

                {{/* Location */}}
                <div class="filter-block relative" :class="{ active: openPanelID === 3 }">
                    <button class="filter-block__header" @click="() => togglePanel(3)">
                        <div class="text-left">
                            <div class="filter-block__title">
                                <T t="programs_in_varosliget.location_filter_label" />
                            </div>
                            <div v-if="!selectedLocations.length">
                                <T t="programs_in_varosliget.location_filter_label_alt" />
                            </div>
                            <div v-else>{{ selectedLocationsText }}</div>
                        </div>
                        <div class="filter-block__chevron">
                            <i class="pi pi-chevron-down"></i>
                        </div>
                    </button>
                    <div class="filter-block__panel lg:-bottom-8">
                        <div class="filter-block__panel-content">
                            <div
                                v-for="option in sortedLocationOptions"
                                :key="`typeOption-${option.id}`"
                                class="p-field flex items-start py-2"
                            >
                                <Checkbox
                                    :id="`chk-type-${option.value}`"
                                    v-model="selectedLocations"
                                    name="location"
                                    :value="option.value"
                                />
                                <label :for="`chk-type-${option.value}`">{{ option.label }}</label>
                            </div>

                            <div v-if="false" class="text-center mt-8 hidden lg:block">
                                <Button :label="$t('generic.search')" @click.native="search" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-full sm:w-auto py-4 lg:p-4">
                    <Button
                        icon="pi pi-search"
                        class="hidden lg:inline-flex"
                        :aria-label="$t('generic.search')"
                        @click.native="search"
                    />
                    <Button
                        :label="$t('generic.search')"
                        class="w-full sm:w-auto lg:hidden"
                        :aria-label="$t('generic.search')"
                        @click.native="search"
                    />
                </div>
            </div>
        </div>

        {{/* Datepicker popup ( mobile & tablet screens ) */}}
        <BaseModal v-if="isCalendarMounted" :close="closeCalendarModal" :is-open="isCalendarModalOpen" auto-width>
            <div>
                <div class="mb-8 ml-3 header-3"><T t="programs_in_varosliget.choose_date" /></div>
                <form @submit.prevent="() => submitDatepickerDateRange()">
                    <ModifiedPrimevueCalendar
                        v-model="tempDatepickerDateRange"
                        selection-mode="range"
                        :number-of-months="2"
                        date-format="YYYY-MM-DD"
                        :min-date="new Date()"
                        :responsive-options="[{ breakpoint: '640px', numMonths: 1 }]"
                        inline
                    />

                    <div class="text-center mt-8">
                        <Button
                            :disabled="!tempDatepickerDateRange.length"
                            type="submit"
                            :label="$t('programs_in_varosliget.datepicker_submit_button_label')"
                            @click.native="submitDatepickerDateRange"
                        />
                    </div>
                </form>
            </div>
        </BaseModal>
    </div>
</template>

<script>
import BaseModal from '@/components/Modals/BaseModal.vue';
import ModifiedPrimevueCalendar from '@/components/Calendar/Calendar.vue';
import events from '@/mixins/events';

export default {
    name: 'EventSearchForm',
    components: {
        ModifiedPrimevueCalendar,
        BaseModal,
    },
    mixins: [events],
    data() {
        return {
            openPanelID: null,
            isCalendarMounted: true,

            // DATE TYPE FILTER STUFF
            isCalendarModalOpen: false,

            // tempDatepickerDateRange is the model value for the datepicker exclusively.
            // The user needs to submit the date picker's form in order to assign
            // this variable's value to selectedDatepickerDateRange.
            tempDatepickerDateRange: [],

            // When tempDatepickerDateRange's value is assigned to selectedDatepickerDateRange,
            // it becomes the value for the custom date range radio input.
            selectedDatepickerDateRange: [],

            // We need to generate a label for this custom radio input too. It is generated
            // from selectedDatepickerDateRange, and depending on whether the two dates held by selectedDatepickerDateRange
            // are different or not, is either formatted as 'MMMM DD - MMMM DD' or just 'MMM DD'.
            customSelectedDateOptionLabelText: '',

            // selectedRange holds the v-model for the date filter radio inputs.
            // Its value is either the range in the format 'YYYY-MM-DD_YYYY-MM-DD', or 'any'
            selectedRange: null,

            rangeOptions: this.$generateDateFilterOptions(),

            // CATEGORY FILTER
            selectedCategory: null,

            // LOCATION TYPE FILTER
            selectedLocations: [],
        };
    },
    computed: {
        locationOptions() {
            return this.$store.getters['data/getEventLocationOptions'];
        },
        categoryOptions() {
            return this.$clone(this.$store.state.data.eventCategories).map((category) => {
                category.label = this.$t(`navigation.category_${category.id}_programs`);
                category.value = category.slug;
                return category;
            });
        },
        sortedLocationOptions() {
            return [...this.locationOptions].sort((a, b) => {
                if (this.selectedLocations.includes(a.value) && this.selectedLocations.includes(b.value)) {
                    return 0;
                }
                if (this.selectedLocations.includes(a.value) && !this.selectedLocations.includes(b.value)) {
                    return -1;
                }
                return 1;
            });
        },
        selectedLocationsText() {
            if (!this.selectedLocations.length) {
                return null;
            }

            const firstSelectedLocation = this.locationOptions.find((x) => x.value === this.selectedLocations[0]).label;

            return `${firstSelectedLocation}${
                this.selectedLocations.length >= 2 ? ` + ${this.selectedLocations.length - 1}` : ''
            }`;
        },
    },
    watch: {
        selectedRange() {
            // If we have a selected range, a custom range option will appear in the date filter panel.
            // Create the labels for this custom range option (month & day)

            if (this.selectedDatepickerDateRange.length) {
                let ret;

                const _d1 = this.$dayjs(this.selectedDatepickerDateRange[0]).format('MMM DD');
                const _d1WithUppercaseInitial = _d1.charAt(0).toUpperCase() + _d1.slice(1);
                const _d2 = this.$dayjs(this.selectedDatepickerDateRange[1]).format('MMM DD');
                const _d2WithUppercaseInitial = _d2.charAt(0).toUpperCase() + _d2.slice(1);

                if (_d1 !== _d2) {
                    ret = `${_d1WithUppercaseInitial} - ${_d2WithUppercaseInitial}`;
                } else {
                    ret = _d1WithUppercaseInitial;
                }

                this.customSelectedDateOptionLabelText = ret;
            }
        },
        '$i18n.locale'() {
            // This is just for aesthetics, to hide the calendar immediately on locale change
            this.isCalendarMounted = false;
        },
    },
    methods: {
        togglePanel(id) {
            if (this.openPanelID === id) {
                this.openPanelID = null;
            } else {
                this.openPanelID = id;
            }

            if (window.innerWidth >= 1024 && this.$refs.searchbar.getBoundingClientRect().y >= window.innerHeight / 3) {
                this.$scrollToElement('#searchbar', 100);
            }
        },
        closeAllPanels() {
            this.openPanelID = null;
        },
        openCalendarModal() {
            this.isCalendarModalOpen = true;
        },
        closeCalendarModal() {
            this.isCalendarModalOpen = false;
        },
        submitDatepickerDateRange(isDesktopCalendar) {
            if (!this.tempDatepickerDateRange.length) {
                return;
            }

            this.selectedDatepickerDateRange[0] = this.$dayjs(this.tempDatepickerDateRange[0]).format('YYYY-MM-DD');
            this.selectedDatepickerDateRange[1] = this.$dayjs(
                this.tempDatepickerDateRange[1] || this.tempDatepickerDateRange[0]
            ).format('YYYY-MM-DD');

            this.selectedRange = `${this.selectedDatepickerDateRange[0]}_${this.selectedDatepickerDateRange[1]}`;

            if (isDesktopCalendar) {
                return;
            }

            this.tempDatepickerDateRange = [];

            this.closeCalendarModal();
        },
        onCalendarDateSelect() {
            this.submitDatepickerDateRange(true);
        },
        search() {
            const query = {};

            if (this.selectedCategory) {
                query.category = this.selectedCategory.value;
            } else {
                query.category = this.EVENT_CATEGORIES.CULTURAL.slug;
            }

            if (this.selectedRange) {
                query.from = this.selectedRange.split('_')[0];
                query.to = this.selectedRange.split('_')[1];
            }

            if (this.selectedLocations.length) {
                query.location = this.selectedLocations.join(',');
            }

            this.$router.push(this.localePath({ name: 'programs-in-varosliget', query }));
        },
    },
};
</script>

<style scoped>
.filter-block {
    @apply w-full border-b border-gray-200;
    @apply py-4 lg:py-0 lg:px-4 lg:border-r lg:border-b-0;
    &::before {
        content: '';
        @apply block absolute;
    }
}

.filter-block__header {
    @apply flex items-center justify-between w-full;
}

.filter-block__title {
    @apply font-Poppins text-sm uppercase font-bold;
}

.filter-block__chevron {
    @apply p-2 inline-flex items-center justify-center transition-all;
}

.filter-block__panel {
    @apply max-h-0 transition-all duration-[0ms] overflow-hidden;
    @apply lg:absolute lg:left-0 lg:w-full lg:translate-y-full lg:opacity-0 lg:pointer-events-none;
    @apply lg:max-h-[unset] !important;
}

.filter-block.active {
    .filter-block__panel {
        @apply max-h-[calc(100%)] lg:opacity-100 lg:pointer-events-auto;
    }
    .filter-block__chevron {
        @apply rotate-180;
    }
}

.filter-block__panel-content {
    @apply pt-4 lg:p-4 lg:bg-white lg:rounded-3xl lg:overflow-hidden;
}
</style>
