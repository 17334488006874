<template>
    <div class="grid grid-cols-12 card-grid-responsive-gap relative">
        <!-- Placeholder box for the first article's thumbnail -->
        <div class="lg:order-1 hidden lg:block lg:col-span-8 aspect-w-16 aspect-h-9"></div>
        <!-- Right side article -->
        <article v-if="articles[1]" class="order-2 anchor-card col-span-12 lg:col-span-4 lg:pb-8 lg:-translate-y-8">
            <NuxtLink
                :to="localePath({ name: 'magazine-article', params: { article: articles[1].slug } })"
                class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 base-responsive-gap"
            >
                <div>
                    <div class="thumbnail base-responsive-rounding aspect-w-3 aspect-h-2">
                        <img
                            v-lazy-load
                            :data-src="$getMediaImage(articles[1].media, 'lead')"
                            :alt="articles[1].title"
                        />
                    </div>
                </div>

                <div>
                    <div class="flex items-center auxiliary-text uppercase mb-4">
                        <time :datetime="articles[1].published_at">
                            {{ $dayjs(articles[1].published_at).format('YYYY/MM/DD') }}
                        </time>
                        <span v-if="articles[1].tag" class="inline">
                            <span class="separator-bullet"></span>
                            {{ articles[1].tag }}
                        </span>
                    </div>

                    <div>
                        <h3 class="header-3 mb-5">{{ articles[1].title }}</h3>
                        <!--                        <p class="line-clamp-3 mb-3">{{ articles[1].description }}</p>-->
                        <Button
                            :label="$t('generic.read_more')"
                            class="p-button-secondary p-button-text -translate-x-2"
                            icon="pi pi-chevron-right"
                        />
                    </div>
                </div>
            </NuxtLink>
        </article>

        <!-- The main featured article text -->
        <article v-if="articles[0]" class="order-1 lg:order-3 anchor-card col-span-12 lg:col-span-5 relative">
            <NuxtLink
                :to="localePath({ name: 'magazine-article', params: { article: articles[0].slug } })"
                class="grid grid-cols-2 base-responsive-gap"
            >
                <div
                    class="main-article-image-container col-span-2 sm:col-span-1 lg:col-span-2 lg:absolute lg:-top-7 lg:left-0"
                >
                    <div class="thumbnail base-responsive-rounding aspect-h-2 aspect-w-3 lg:aspect-w-16 lg:aspect-h-9">
                        <img
                            v-lazy-load
                            :data-src="$getMediaImage(articles[0].media, 'lead')"
                            :alt="articles[0].title"
                        />
                    </div>
                </div>

                <div class="col-span-2 sm:col-span-1 lg:col-span-2">
                    <div class="flex items-center auxiliary-text uppercase mb-4">
                        <time :datetime="articles[0].published_at">
                            {{ $dayjs(articles[0].published_at).format('YYYY/MM/DD') }}
                        </time>
                        <span v-if="articles[0].tag" class="inline">
                            <span class="separator-bullet"></span>
                            {{ articles[0].tag }}
                        </span>
                    </div>
                    <h3 class="header-3 mb-5">{{ articles[0].title }}</h3>
                    <div class="mb-3" v-html="articles[0].lead_text"></div>
                    <Button
                        :label="$t('generic.read_more')"
                        class="p-button-secondary p-button-text -translate-x-2"
                        icon="pi pi-chevron-right"
                    />
                </div>
            </NuxtLink>
        </article>
        <!-- Bottom article -->
        <article v-if="articles[2]" class="order-3 lg:order-4 anchor-card col-span-12 lg:col-span-6 lg:col-start-7">
            <NuxtLink
                :to="localePath({ name: 'magazine-article', params: { article: articles[2].slug } })"
                class="grid grid-cols-1 sm:grid-cols-2 base-responsive-gap"
            >
                <div>
                    <div class="thumbnail base-responsive-rounding aspect-h-2 aspect-w-3 lg:aspect-w-1 lg:aspect-h-1">
                        <img
                            v-lazy-load
                            :data-src="$getMediaImage(articles[2].media, 'lead')"
                            :alt="articles[2].title"
                        />
                    </div>
                </div>

                <div>
                    <div class="flex items-center auxiliary-text uppercase mb-4">
                        <time :datetime="articles[2].published_at">
                            {{ $dayjs(articles[2].published_at).format('YYYY/MM/DD') }}
                        </time>
                        <span v-if="articles[2].tag" class="inline">
                            <span class="separator-bullet"></span>
                            {{ articles[2].tag }}
                        </span>
                    </div>

                    <div>
                        <h3 class="header-3 mb-5">{{ articles[2].title }}</h3>
                        <!--                        <p class="line-clamp-3 mb-3">{{ articles[2].description }}</p>-->
                        <Button
                            :label="$t('generic.read_more')"
                            class="p-button-secondary p-button-text -translate-x-2"
                            icon="pi pi-chevron-right"
                        />
                    </div>
                </div>
            </NuxtLink>
        </article>
    </div>
</template>

<script>
export default {
    name: 'FeaturedArticlesGroupBlock',
    props: {
        articles: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style scoped>
.main-article-image-container {
    @apply lg:w-[calc(992px/12*8-0.6rem)] xl:w-[calc(1140px/12*8-0.6rem)] 2xl:w-[calc(1440px/12*8-0.6rem)] 3xl:w-[calc(70vw/12*8-0.6rem)] lg:-translate-y-full;
}
</style>
