<template>
    <div class="absolute bottom-0 left-0 w-full bg-gradient-to-t from-black/60 px-5 pb-6 pt-12 pointer-events-none">
        <div class="flex items-center">
            <div class="flex items-center mr-4 relative px-1">
                <div class="absolute top-1/2 left-0 -translate-y-1/2 bg-white w-full h-[1px]"></div>
                <div class="bullet"></div>
                <div class="bullet"></div>
                <div class="bullet"></div>
            </div>
            <span class="font-Poppins font-bold text-sm uppercase text-white">Programsorozat</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EventSeriesBadge',
};
</script>

<style scoped>
.bullet {
    @apply w-2.5 h-2.5 rounded-full bg-white mx-1;
}
</style>
